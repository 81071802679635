import $ from 'jquery'
import DOMelement from './DOMelement'
import { translate } from '@/tools'
import { toNumber } from 'lodash'

export default class InfoPopup extends DOMelement {
  constructor () {
    super('info-popup.html')
    this.addToDOM($('body'))
  }

  peek (message) {
    message = translate(message)
    const duration =
            toNumber(
              $(this.document).find('p').css('transition-duration')
            ) * 1000

    let wait = 0
    if ($(this.document).is('.open')) {
      $(this.document).removeClass('open').addClass('close-fast')
      wait =
                toNumber(
                  $(this.document).find('p').css('transition-duration')
                ) * 1000
    }

    setTimeout(() => {
      $(this.document).removeClass('close-fast')
      $(this.document).find('p span').html(message)
      $(this.document)
        .addClass('open')
        .attr({ timestamp: new Date().getTime() })

      const delay = 3000
      setTimeout(() => {
        if (
          new Date().getTime() -
                        Number($(this.document).attr('timestamp')) >=
                    duration + delay
        ) {
          $(this.document).removeClass('open')
        }
      }, duration + delay)
    }, wait)
  }
}
