import { APP, HOST } from '..'
import $ from 'jquery'
import DOMelement from './DOMelement'
import { formatDate, markdown, translate } from '@/tools'
import trackGraph from '../../assets/images/track_graph.svg'
import loadingSmall from '../../assets/images/loading-small.svg'
import graphLoading from '../../assets/images/graph-loading.svg'
import svgFiles from './svgFiles'
import elevationDiagram from '@/utils/elevation/elevationDiagram'

export default class Article extends DOMelement {
  static instances = []
  static promises = []

  constructor (parent) {
    super(`articles/${parent.family}.html`)

    this.isOpen = false
    this.parent = parent
    this.parsed = false

    this.promises = this.promises.concat(parent.promises)

    if (!('scroller' in Article)) {
      Article.scroller = new DOMelement('scroller.html')
      this.promises = this.promises.concat(Article.scroller.promises)
      Article.scroller.getCopy().then((response) => {
        Article.scroller = response
      })
    }

    if (!('dataList' in Article)) {
      Article.dataList = new DOMelement('data-list.html')
      this.promises = this.promises.concat(Article.dataList.promises)
      Article.dataList.getCopy().then((response) => {
        Article.dataList = response
      })
    }

    // Add to master list
    Article.instances.push(this)
    Article.promises = Article.promises.concat(this.promises)
  }

  parse = () => {
    if (!this.parsed) {
      this.parsed = true
      Promise.all(Article.promises).then(() => {
        this.buildParagraphs()
        $(
          this.buildDataList(this.parent.attributes, this.parent)
        ).insertAfter($(this.document).find('.title').first())
        this.buildPhotos()
        this.buildScroller()
        if ('family' in this.parent) {
          this[this.parent.family]()
        }
      })
    }
  }

  buildParagraphs = () => {
    $(this.document)
      .find('.paragraph')
      .each((_, paragraph) => {
        const name = $(paragraph).attr('data-name')
        if (this.parent.texts[name] == null) {
          $(paragraph).prev().remove()
          $(paragraph).remove()
        } else {
          $(paragraph).html(
            markdown(this.parent.texts[name])
          )
        }
      })
  }

  buildDataList = (attributes, data, title = null) => {
    $(this.document).remove('ul.data')
    let dataList = $(Article.dataList)
    if (attributes) {
      Object.values(attributes).forEach((attribute) => {
        if (
          attribute.name in data &&
                    data[attribute.name] != null &&
                    !isNaN(data[attribute.name]) &&
                    attribute.in_data_list
        ) {
          const value = data[attribute.name]
          const li = $(dataList)
            .find('li.blank')
            .clone()
            .removeAttr('class')
            .attr({ 'data-name': attribute.name })
            .appendTo(dataList)

          $(li)
            .find('img')
            .attr({ src: 'data:image/svg+xml;base64,' + btoa(svgFiles[attribute.name]) })

          $(li).find('span.title').html(attribute.title)

          let cssClass = ''
          if (value === 0) {
            cssClass = 'striken'
          }

          switch (attribute.type) {
            // Named value
            case 'cotation':
              $(li)
                .find('span.value')
                .html(attribute.data[value])
              break
              // Value, can have a unit
            case 'value':
              if (attribute.data.unit === '' && value === 0) {
                $(li)
                  .attr({ class: cssClass })
                  .find('span.value')
                  .remove()
              } else {
                $(li)
                  .attr({ class: cssClass })
                  .find('span.value')
                  .html(value + attribute.data.unit)
              }
              break
              // On or Off
            case 'bool':
              $(li)
                .attr({ class: cssClass })
                .find('span.value')
                .remove()
              break
          }
        }
      })
    }

    // Remove blank li
    $(dataList).find('li.blank').remove()

    if ($(dataList).find('li').length === 0) {
      $(dataList).remove()
    }

    // Add title
    if (title) {
      $(dataList).prepend(`<h3>${title}</h3>`)
    }

    if (!$(dataList).find('li').length) {
      dataList = ''
    }

    // Add to article
    return dataList
  }

  buildPhotos = () => {
    const photosUL = $(this.document).find('ul.photos')
    if (
      !('photos' in this.parent) ||
            this.parent.photos == null ||
            this.parent.photos.length === 0
    ) {
      $(photosUL).remove()
      return
    }

    // Add photos
    this.parent.photos.forEach(function (photo, index, array) {
      if (array.length > 8 && index > 7) {
        return
      }
      let src = photo
      if (src.includes('camptocamp')) {
        src = src.replace('.jpg', 'SI.jpg')
      }

      $(photosUL)
        .find('li.blank')
        .clone()
        .attr('data-url', photo)
        .css({ 'background-image': `url(${src})` })
        .removeAttr('class')
        .appendTo(photosUL)
    })

    if (this.parent.photos.length < 9) {
      $(photosUL).find('li.moreImages').remove()
    } else {
      $(photosUL).append($(photosUL).find('li.moreImages'))
      $(photosUL).find('li.moreImages span').text(this.parent.photos.length)
    }

    $(photosUL).find('li.blank').remove()
  }

  buildScroller = () => {
    const scroller = $(Article.scroller)
    if ($(this.document).is('[data-scroller]')) {
      $(this.document).append(scroller)
      $(this.document)
        .find('div.scroller')
        .append(
          $(this.document).find('[data-scroller-element="true"]')
        )
    }
    this.buildSelector(document)
  }

  buildSelector = () => {
    const selector = $(this.document).find('ul.selector')
    const scroller = $(this.document).find('div.scroller')
    const name = this.parent.name

    // Get list of articles and their titles
    const list = {}
    $(scroller)
      .children()
      .each((index, element) => {
        list[$(element).attr('class')] = $.trim(
          $(element)
            .find('[data-scroller-title]')
            .first()
            .text()
            .replace(/\r?\n|\r/gm, '')
        )
      })

    // Create selector items from list
    for (const item in list) {
      $(selector)
        .children('li.blank')
        .first()
        .clone()
        .removeAttr('class')
        .appendTo(selector)
      $(selector)
        .find('input')
        .last()
        .attr({
          name: `${name}Selector`,
          id: item
        })
      $(selector)
        .find('label')
        .last()
        .attr({
          for: item
        })
        .html(`${list[item]}`)

      // Add corrseponding image if it exists
      $(selector)
        .find(`label[for="${item}"]`)
        .prepend(`<img src="${'data:image/svg+xml;base64,' + btoa(svgFiles[item])}">`)

      $(selector)
        .find(`label[for="${item}"] img`)
        .on('error', (event) => {
          $(event.target).css({ display: 'none' })
        })
    }

    // Remove blank selector and show selector
    $(selector).children('li.blank').remove()
    $(selector).addClass('visible')

    this.setTriggers()
  }

  static getOpenArticle = () => {
    for (const article of Article.instances.values()) {
      if (article.isOpen) {
        return article
      }
    }
    return null
  }

  static reset = () => {
    $('div.articles').empty()
    $('div.articles').scrollTop(0)
  }

  static close = () => {
    $('body').removeAttr('data-articles')
    Article.reset()
    const openArticle = Article.getOpenArticle()
    if (openArticle) {
      openArticle.isOpen = false
      APP.state.article = null
      APP.state.refresh()
      return openArticle instanceof Article
    }
    APP.state.article = null
    APP.state.refresh()
    return null
  }

  open = (dontWait = false) => {
    if (Article.getOpenArticle() === this) {
      return
    }

    Article.reset()

    this.promises = this.promises.concat(Article.promises)
    this.promises = this.promises.concat(this.parent.promises)
    let promises = this.promises
    if (dontWait) {
      promises = []
    }

    Promise.all(promises).then(async () => {
      this.parse({
        data: this.parent
      })

      // Close other open articles
      const openArticle = Article.getOpenArticle()
      if (openArticle) {
        openArticle.isOpen = false
      }

      Article.reset()
      $('body').attr({ 'data-articles': 'true' })
      $('div.articles').append('&nbsp;')
      this.isOpen = true

      // Set required search bar
      APP.ui.searchBar.update()

      // Open hamburger menu
      APP.ui.tabs.open()

      // Set map
      if (this.parent.family === 'tracks') {
        APP.map.flyToBounds(this.parent.polyline.getBounds())
      }

      $('div.articles').append(this.document)
      this.reduceParagraphs()
      this.setTriggers()

      // Set bookmarks button
      const bookmarks = localStorage.getItem(this.parent.family)
      if (bookmarks) {
        const data = JSON.parse(bookmarks)
        if (data && data.includes(this.parent.name)) {
          $(this.document)
            .find('input[type="checkbox"][name="bookmark"]')
            .prop('checked', true)
        } else {
          $(this.document)
            .find('input[type="checkbox"][name="bookmark"]')
            .prop('checked', false)
        }
      }

      APP.state.article = {
        family: this.parent.family,
        name: this.parent.name
      }
      APP.urlHandler.update()
    })
  }

  refresh = () => {
    // Set loading icon
    $(this.document)
      .find('ul.data li span.value')
      .html($('<img>', { src: loadingSmall, alt: 'Loading' }))
    $(this.document)
      .find('img.track_graph')
      .attr({
        src: trackGraph
      })
      .addClass('loading')

    const domElement = new DOMelement(this.url).getCopy()
    Promise.resolve(domElement).then((response) => {
      this.document = $(response)
      const promises = this.promises.concat(this.parent.promises)

      Promise.all(promises).then(() => {
        $('div.articles').empty().append(this.document)
        this.parsed = false
        this.parse()
      })
    })
  }

  reduceParagraphs = () => {
    $(this.document)
      .find('.paragraph')
      .each((index, paragraph) => {
        if ($(paragraph).height() > 200) {
          $(paragraph).addClass('reduced')
        }
      })
  }

  setTriggers = () => {
    // Report links
    $(this.document)
      .find('section > p.links > a[data-site="meteoFrance"]')
      .off()
      .on('click', () => {
        const url = `https://donneespubliques.meteofrance.fr/donnees_libres/Pdf/BRA/BRA.${this.parent.reportName}.pdf`
        window.open('/php/forceDownload.php?url=' + encodeURI(url))
      })

    // Selector
    $(this.document)
      .find('ul.selector')
      .children('li')
      .off()
      .on('click', (event) => this.clickedSelector(event))
    $(this.document)
      .find('ul.selector')
      .children('li')
      .first()
      .find('input')
      .trigger('click')

    // Photos
    $(this.document)
      .find('ul.photos li')
      .off()
      .on('click', (event) => {
        APP.ui.carousel.open({
          poi: this.parent,
          target: event.originalEvent.target
        })
      })

    // Actions
    $(this.document)
      .find('ul.actions li')
      .off()
      .on('click', (event) => {
        const action = $(event.currentTarget).attr('data-action')
        this.parent[action]($(event.currentTarget).find('input'))
      })
    $(this.document)
      .find('ul.actions li')
      .find('label, input')
      .on('click', (event) => {
        event.stopPropagation()
      })

    // Paragraphs
    $(this.document)
      .find('.paragraph.reduced')
      .each((index, paragraph) => {
        $(paragraph)
          .off('click')
          .on('click', (event) => {
            $(event.currentTarget).toggleClass('reduced')
            $(event.currentTarget).toggleClass('expanded')
          })
      })

    // Favorite button
    $(this.document)
      .find('input.bookmark')
      .off()
      .on('change', (event) => {
        const value = $(event.target).is(':checked')

        this.bookmark(value)

        if (value) {
          APP.ui.infoPopup.peek('bookmarks_added')
        } else {
          APP.ui.infoPopup.peek('bookmarks_removed')
        }
      })

    this.setTriggerCallback()
  }

  setTriggerCallback = () => {

  }

  bookmark = (bool) => {
    // Get localStorage data
    let data = localStorage.getItem(this.parent.family)
    if (data == null) {
      data = []
    } else {
      data = JSON.parse(data)
    }

    // Add/remove current
    if (bool) {
      data.push(this.parent.name)
    } else {
      data = data.filter((item) => item !== this.parent.name)
    }

    // Update localStorage data
    localStorage.setItem(this.parent.family, JSON.stringify(data))

    APP.ui.bookmarks.updateRoot()
  }

  clickedSelector (event) {
    const target = event.currentTarget
    const selector = $(target).parents('ul').first()
    const parent = $(selector).parent()
    const scroller = $(parent).find('.scroller').first()

    // Show all elements
    $(scroller).children().removeClass('hidden')

    // Get index of clicked element and add it as a CSS variable
    const list = Object.values($(selector).find('li'))
    const index = list.indexOf(target)
    $(parent).css({ '--index': index }).attr({ 'data-index': index })

    // Get animation timing and hide other children after that time
    const duration = parseFloat($(scroller).css('transition-duration'))
    const timestamp = Date.now()
    $(scroller).attr({ 'data-animation-timestamp': timestamp })
    setTimeout(() => {
      if (
        $(scroller).attr('data-animation-timestamp') ===
                timestamp.toString()
      ) {
        const index = parseInt($(parent).attr('data-index')) + 1
        $(scroller)
          .children(`:not(:nth-child(${index}))`)
          .addClass('hidden')
        $(scroller).removeAttr('data-animation-timestamp')
      }
    }, duration * 1000)

    // Scroll selector to element
    const containerPaddingValue = parseInt(selector.css('padding-left'))
    const containerScrollValue = parseInt(selector.scrollLeft())
    const containerWidth = parseInt(selector.css('width'))
    const containerMaxScroll =
            $(selector)[0].scrollWidth - $(selector).outerWidth()
    const itemScrollPosition =
            parseInt($(target).position().left) -
            parseInt($(target).parent().position().left) -
            containerPaddingValue
    const itemWidth = parseInt($(target).css('width'))
    const scrollValue = Math.min(
      containerMaxScroll,
      Math.max(
        0,
        containerScrollValue +
                    itemScrollPosition -
                    containerWidth / 2 +
                    itemWidth / 2
      )
    )

    selector.stop().animate({ scrollLeft: scrollValue }, 500)

    // Update Article and URL
    this.selectedPage = {
      id: index,
      name: $(target).find('label').attr('for'),
      title: $(target).find('label').text()
    }
  }

  avalancheRisk = (coordinates) => {
    /* for (let name in APP.regions) {
            // Find polygon
            let polygon = undefined
            APP.regions[name].layer.eachLayer((layer) => {
                if (layer instanceof L.Polygon) {
                    polygon = layer
                }
            })

            let bool = isMarkerInsidePolygon(
                new L.Point(coordinates.latitude, coordinates.longitude),
                polygon
            )
            if (bool) {

            }
        } */
  }

  refuges = () => {
    // Title
    $(this.document).find('section > h2').html(this.parent.title)

    // Type
    $(this.document)
      .find('h5.type')
      .insertAfter($(this.document).find('.title'))

    // Type
    $(this.document)
      .find('section > h5.type')
      .html(APP.attributes.refuge_type.data[this.parent.refuge_type])

    // Parse BBCode
    $(this.document)
      .find('.paragraph')
      .each((index, paragraph) => {
        const name = $(paragraph).attr('class').split(' ').at(1)
        if (this.parent.texts[name] != null) {
          this.parent.texts[name] = markdown(
            this.parent.texts[name]
          )
        }
      })

    // Links
    $(this.document)
      .find('p.links a[data-site="refuges.info"]')
      .attr({
        href: `https://www.refuges.info/point/${this.parent.name}`
      })
    if (this.parent.website != null) {
      $(this.document)
        .find('p.links a[data-site="website"]')
        .attr({ href: this.parent.website })
    } else {
      $(this.document).find('p.links a[data-site="website"]').remove()
    }

    /* if(this.parent.amenities_staffed){
            let dataList = this.buildDataList(
                this.parent.attributes,
                this.parent.amenities_staffed,
                'Confort en gardiennage'
            )
            $(dataList).insertAfter($(this.document).find('.title').first())
        } */

    if (this.parent.amenities_unstaffed) {
      const dataList = this.buildDataList(
        this.parent.attributes,
        this.parent.amenities_unstaffed,
        'Confort hors-gardiennage'
      )
      $(dataList).insertAfter($(this.document).find('h5.type').first())
    }
  }

  sorties = () => {
    // Title
    $(this.document).find('section > h2').html(this.parent.title)

    // Date
    $(this.document)
      .find('span.date')
      .html(
                `Par ${this.parent.author} le ${formatDate(
                    this.parent.date,
                    'frenchDate'
                )}`
      )

    // Links
    $(this.document)
      .find('p.links a[data-site="c2c"]')
      .attr({
        href: `https://www.camptocamp.org/outings/${
                    this.parent.name.split('_')[1]
                }`
      })
    $(this.document)
      .find('p.links a[data-site="skitour"]')
      .attr({
        href: `https://skitour.fr/sorties/${
                    this.parent.name.split('_')[1]
                }`
      })
    $(this.document)
      .find(
                `p.links a[data-site]:not([data-site="${this.parent.origin}"])`
      )
      .remove()
    if (!this.parent.has_geojson) {
      $(this.document).find('li[data-action="download_geojson"]').remove()
    }
  }

  topos = (data) => {
    // Title
    $(this.document).find('section > h2').html(this.parent.title)

    // Links
    $(this.document)
      .find('p.links a[data-site="c2c"]')
      .attr({
        href: `https://www.camptocamp.org/routes/${this.parent.name}`
      })

    // Append BRA to the end of the article
    this.avalancheRisk({
      latitude: this.parent.latitude,
      longitude: this.parent.longitude
    })
    if (!this.parent.has_geojson) {
      $(this.document).find('li[data-action="download_geojson"]').remove()
      $(this.document).find('li[data-action="save_geojson"]').remove()
    }
  }

  tracks = () => {
    // Title
    $(this.document).find('section > input.title').val(this.parent.title)

    // Temp diagram
    $(this.document).find('img.track_graph').attr({
      src: 'data:image/svg+xml;base64,' + btoa(graphLoading)
    })

    elevationDiagram(this.parent.geojson.geometry)
      .then(async (response) => {
        const svg = await response.text()
        $(this.document).find('img.track_graph').attr({
          src: 'data:image/svg+xml;base64,' + btoa(svg)
        })

        this.setTriggerCallback = () => {
          $(this.document)
            .find('input.title')
            .off()
            .on('change input', (event) => {
              this.parent.rename($(event.target).val())
            })
        }
        this.setTriggerCallback()
      })
  }

  stations = () => {
    // Title
    $(this.document).find('section > h2').html(this.parent.title)
  }

  webcams = () => {
    // Title
    $(this.document).find('section > h2').html(this.parent.title)

    // URL
    let url = this.parent.website
    if (url == null) {
      url = this.parent.url_image
    }

    // View
    let image = null
    if (this.parent.timestamps && this.parent.timestamps.length > 0) {
      image = `../images/webcams_images/${
                this.parent.name
            }/${this.parent.timestamps.at(-1)}.webp`
    }
    if (image) {
      $(this.document)
        .find('p.view')
        .css({ 'background-image': `url(${image})` })
        .removeClass('no-image')
    }

    this.setTriggerCallback = () => {
      if (image) {
        $(this.document)
          .find('p.view')
          .off()
          .on('click', () => {
            APP.ui.carousel.open({
              poi: this.parent
            })
          })
      }
    }
    this.setTriggerCallback()

    // Date
    const imageDate =
            this.parent.timestamps && this.parent.timestamps.length > 0
              ? formatDate(
                new Date(this.parent.timestamps.at(-1) * 1000),
                'frenchDateTime'
              )
              : null
    $(this.document).find('p.image-date').html(imageDate)

    // Orientation
    const orientation = Math.floor(
      ((this.parent.orientation + 360 / 16) / 360) * 8
    )
    $(this.document)
      .find('li[data-name="orientation"] span.value')
      .html(this.parent.attributes.orientation.data[orientation])

    // Website
    $(this.document)
      .find('p.links a[data-site="website"]')
      .attr({ href: url })
  }

  regions = () => {
    // Titles
    $(this.document).find('h1').html(this.parent.title)
    $(this.document)
      .find('section h3.riskTitle')
      .html(
        APP.attributes.avalanche_risk.data[
          this.parent.report.avalanche_risk.max_risk
        ]
      )

    // Texts
    for (const key of ['avalanche_risk', 'snow_cover']) {
      if ('texts' in this.parent.report[key]) {
        for (const [name, value] of Object.entries(
          this.parent.report[key].texts
        )) {
          $(this.document)
            .find(`.${key} div.${name}`)
            .html(markdown(value))
          $(this.document)
            .find(`.${key} h3.${name}`)
            .html(translate(name))
        }
      }
    }

    // Date
    if (this.parent.report.timestamp !== 0) {
      const timePast = parseInt(
        (Date.now() - this.parent.report.timestamp * 1000) /
                    1000 /
                    60 /
                    60
      )
      const date = formatDate(
        new Date(this.parent.report.timestamp * 1000),
        'frenchDateTime'
      )
      $(this.document)
        .find('h5.date')
        .html(`Données du ${date} (${timePast}h)`)
      if (timePast > 20) {
        $(this.document).find('h5.date').addClass('warning')
      }
    }

    // Enneigement diagram
    $(this.document)
      .find('section.snow_cover > div.diagrammeEnneigement h2.highest')
      .html(this.parent.highest_point_name)
    $(this.document)
      .find('section.snow_cover > div.diagrammeEnneigement h3.highest')
      .html(this.parent.highest_point_elevation + 'm')
    $(this.document)
      .find('section.snow_cover > div.diagrammeEnneigement h3.lowest')
      .html(this.parent.lowest_point_elevation + 'm')
    $(this.document)
      .find('section.snow_cover > div.diagrammeEnneigement h2.lowest')
      .html(this.parent.lowest_point_name)

    // Diagrams
    $(this.document)
      .find('img.diagram')
      .each((_, diagram) => {
        $(diagram).attr({
          src: `${HOST}/images/diagrams/${$(diagram).attr('data-name')}/${this.parent.name}.svg`
        })
      })

    $('a[data-action="pdf-download"]').attr({
      href: this.parent.url
    })
  }

  filters = () => {
    $(this.document).attr({ 'data-family': this.parent.name })

    // Set main title
    $(this.document).find('h2').append(this.parent.title)
    $(this.document)
      .find('h2 img')
      .attr({ src: `../images/${this.parent.name}.svg` })

    // Append filters
    Object.values(this.parent.filters).forEach((filter) => {
      Promise.all(filter.promises).then(() => {
        $(this.document)
          .find('section')
          .append(filter.formControl.document)
      })
    })

    this.setTriggers_ = this.setTriggers
    this.setTriggers = () => {
      this.setTriggers_()

      // Apply each filter's formControl triggers
      Object.values(this.parent.filters).forEach((filter) => {
        filter.formControl.setTriggers()
        filter.formControl.setValue()
      })
    }
  }
}
